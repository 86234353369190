import React from 'react';
import * as Accessa from 'accessa';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
// eslint-disable-next-line
import IconLabel from 'q3-components/src/IconLabel';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import { AddressHeader } from 'q3-ui/src/address';
import PhoneIcon from '@material-ui/icons/Phone';
import { Phone, Email } from 'q3-ui/src/link';
import { makeStyles } from '@material-ui/core/styles';
import ContactForm from '../containers/ContactForm';
import SEOHelmet from '../components/SEOHelmet';

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  column: {
    flex: 1,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  stack: {},
  inherit: ({ justifyContent }) => ({
    '& *': {
      color: 'inherit',
    },
    [theme.breakpoints.down('xs')]: {
      '& div': {
        display: 'block',
      },
      '& span': {
        display: 'flex',
        justifyContent,
        width: '100%',
      },
      '& a': {
        display: 'block',
        marginBottom: '1rem',
      },
    },
  }),
}));

const ContactInformationList = ({
  email,
  tollFree,
  local,
  fax,
  ...rest
}) => {
  const cls = useStyle(rest);
  return (
    <Box className={cls.inherit}>
      {email && (
        <IconLabel icon={EmailIcon} label="Email">
          <Email address={email} />
        </IconLabel>
      )}
      {tollFree && (
        <IconLabel icon={PhoneIcon} label="Toll-free">
          <Phone number={tollFree} />
        </IconLabel>
      )}
      {local && (
        <IconLabel icon={PhoneIcon} label="Phone">
          <Phone number={local} />
        </IconLabel>
      )}
      {fax && (
        <IconLabel icon={PrintIcon} label="Fax">
          <Phone number={fax} />
        </IconLabel>
      )}
    </Box>
  );
};

const ContactPage = ({ data }) => {
  const cls = useStyle();
  return (
    <Paper elevation={0}>
      <SEOHelmet
        title={
          get(data, 'page.seo.title') ||
          get(data, 'page.banner.title')
        }
        description={get(
          data,
          'page.seo.metaDescription.metaDescription',
        )}
      />
      <Accessa.Layouts.Neapolitan
        reverseOnMobile
        focalComponent={
          <Box
            minHeight="550px"
            width="100%"
            height="100%"
            position="relative"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
            >
              <Accessa.Partials.Compass
                apiKey="AIzaSyCt7yombMtPIeU_-mWAi4_3iuOfh-Z_LY0"
                street={get(
                  data,
                  'contentfulConfig.streetLine',
                )}
                city={get(data, 'contentfulConfig.city')}
                postal={get(
                  data,
                  'contentfulConfig.postalCode',
                )}
              />
            </Box>
          </Box>
        }
      >
        <Box className={cls.container}>
          <Typography variant="h1">
            {get(data, 'page.banner.title')}
          </Typography>
          <Box my={4}>
            <ContactForm origin="Contact" />
          </Box>
          <Grid container spacing={3}>
            {get(
              data,
              'contentfulConfig.departments',
              [],
            ).map((dep) => (
              <Grid key={dep.name} item xs={12} lg={4}>
                <AddressHeader title={dep.name} />
                <ContactInformationList {...dep} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Accessa.Layouts.Neapolitan>
    </Paper>
  );
};

export default ContactPage;

export const query = graphql`
  {
    page: contentfulPage(
      contentful_id: { eq: "8GpukqcNH7gCSYdd6C1Ct" }
    ) {
      banner {
        title
      }
      body {
        raw
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
    }
    contentfulConfig {
      streetLine
      city
      region
      postalCode
      socialMedia
      departments {
        name
        fax
        email
        local
        tollFree
      }
    }
  }
`;
