import React from 'react';
import { navigate } from '@reach/router';
import { Form, Field } from 'q3-ui-forms/src/builders';
import useRest from 'q3-ui-rest';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import { handleCapt } from '../components/Recaptcha';
import { CONTACT_ORIGIN } from '../constants';

const {
  OUTREACH: {
    COLLECTION_NAME,
    ENDPOINT,
    RESOURCE_NAME,
    RESOURCE_NAME_SINGULAR,
  },
} = COLLECTIONS;

export default ({ origin }) => {
  const { post } = useRest({
    url: ENDPOINT,
    collectionName: COLLECTION_NAME,
    pluralized: RESOURCE_NAME,
    key: RESOURCE_NAME_SINGULAR,
    runOnInit: false,
  });

  const columnValues = {
    xl: 4,
    lg: 4,
    md: 4,
    required: true,
  };

  return (
    <Form
      restart
      showSuccessMessage
      onSubmit={handleCapt((values) =>
        post(values)
          .then(() => {
            navigate('/contact-thanks');
          })
          .catch(() => {
            const e = new Error();
            e.message = 'contactFailed';
            throw e;
          }),
      )}
      initialValues={{
        resourceOrigin: origin,
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        company: '',
        message: '',
        subject: '',
        postal: '',
      }}
    >
      <Field
        name="firstName"
        type="text"
        {...columnValues}
      />
      <Field
        name="lastName"
        type="text"
        {...columnValues}
      />
      <Field name="company" type="text" {...columnValues} />
      <Field name="email" type="email" {...columnValues} />
      <Field name="tel" type="tel" {...columnValues} />
      <Field
        name="postal"
        type="postal"
        {...columnValues}
      />
      <Field
        name="subject"
        type="text"
        xl={12}
        lg={12}
        conditional={[`resourceOrigin!=${CONTACT_ORIGIN}`]}
      />
      <Field
        name="message"
        type="text"
        multiline
        rows={5}
        required
        xl={12}
        lg={12}
      />
    </Form>
  );
};
